<!--
  PACKAGE_NAME : src\pages\esp\summary\menu-used.vue
  FILE_NAME : menu-used
  AUTHOR : supark
  DATE : 2021-08-05
  DESCRIPTION : 메뉴 이용 현황
-->
<template>
  <div class="page-sub-box sub_new_style01 sub_ui_box1">
    <!--"sub_new_style01" class적용시 상단라인 삭제-->

    <!--검색영역-->
    <div class="page_search_box border0 mar_b10">
      <div class="inner clearfix">
        <span class="fl text-type01">조회기간</span>
        <div class="ui-datepicker period fl mar_ri10">
          <div class="ui-datepicker-item">
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              :width="120"
              v-model="startDate"
              type="date"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :height="34"
            >
            </DxDateBox>
          </div>
          <div class="dash">~</div>
          <div class="ui-datepicker-item">
            <DxDateBox
              :styling-mode="stylingMode"
              :width="120"
              v-model="endDate"
              type="date"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :height="34"
            >
            </DxDateBox>
          </div>
        </div>
        <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="34" @click="onSearch" />
      </div>
    </div>
    <!-- //검색영역-->

    <!--2022.01.16 스크롤 영역을 -> 그래프 영역으로 변경-->
    <div class="set-graph-box">
      <DxChart width="100%" id="chart" :data-source="sortMenuUsedData" @pointClick="onPointClick">
        <DxCommonSeriesSettings argument-field="menuNm" type="bar" hover-mode="allArgumentPoints" selection-mode="allArgumentPoints">
        </DxCommonSeriesSettings>
        <DxSeries value-field="uv" name="방문자수(UV)" color="#00c3c8" />
        <DxSeries value-field="pv" name="페이지뷰(PV)" color="#4fe4e0" />
        <DxTooltip :enabled="true" :shared="true" :customize-tooltip="customizeTooltip" />
        <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
        <DxExport :enabled="false" />
      </DxChart>
    </div>
    <!-- //2022.01.16 스크롤 영역을 -> 그래프 영역으로 변경-->

    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @option-changed="onHandlePropertyChange" />
  </div>
</template>

<script>
  import Query from 'devextreme/data/query';

  import { DxChart, DxSeries, DxCommonSeriesSettings, DxLegend, DxExport, DxTooltip } from 'devextreme-vue/chart';
  import DxDateBox from 'devextreme-vue/date-box';
  import { isSuccess } from '@/plugins/common-lib';
  import DxButton from 'devextreme-vue/button';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxChart,
      DxSeries,
      DxCommonSeriesSettings,
      DxLegend,
      DxExport,
      DxDateBox,
      DxTooltip,
      DxButton,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        dataGrid: {
          refName: 'menuUseGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling: { // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },

          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '순위',
              dataField: 'index',
              width: 80,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '대메뉴',
              dataField: 'menuDept1',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowSorting: false,
            },
            {
              caption: '중메뉴',
              dataField: 'menuDept2',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '소메뉴',
              dataField: 'menuNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '방문자수(uv)',
              dataField: 'uv',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'desc',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '페이지뷰(pv)',
              dataField: 'pv',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        menuUsedList: [],
        startDate: null,
        endDate: null,
        sortColumn: 'uv',
        sortOrderDesc: true,
      };
    },
    computed: {
      getMenuUsedData() {
        let result = [];
        let vm = this;
        this.menuUsedList.reduce(function (res, value) {
          let menu = vm.$store.getters.getMenuList.find(d => d.id === value.menuId);

          if (menu && menu.menuDepth === 3) {
            let menuDept2 = vm.$store.getters.getMenuList.find(d => d.id === menu.parentId);
            if (menuDept2 && !res[value.menuId]) {
              res[value.menuId] = {
                menuId: value.menuId,
                menuDept1: vm.$store.getters.getMenuList.find(d => d.id === menuDept2.parentId)?.menuNm || '',
                menuDept2: menuDept2.menuNm,
                menuNm: menu.menuNm,
                uv: 0,
                pv: 0,
              };

              result.push(res[value.menuId]);
            }

            if (menuDept2) {
              res[value.menuId].uv += value.uv;
              res[value.menuId].pv += value.pv;
            }
          }

          return res;
        }, {});
        return result;
      },
      sortMenuUsedData() {
        let data = Query(this.getMenuUsedData).sortBy(this.sortColumn, this.sortOrderDesc).toArray();
        let i = 0;
        data.forEach(element => {
          i += 1;
          element['index'] = i;
        });
        return data;
      },
    },
    methods: {
      customizeTooltip(pointInfo) {
        return {
          html: `<div><div class='menu-used-tooltip-header'>${pointInfo.argumentText}</div><div class='menu-used-tooltip-body'><div class='series-name'><span class='top-series-name'>${pointInfo.points[0].seriesName}</span>: </div><div class='value-text'><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div><div class='series-name'><span class='bottom-series-name'>${pointInfo.points[1].seriesName}</span>: </div><div class='value-text'><span class='bottom-series-value'>${pointInfo.points[1].valueText}</span></div></div></div>`,
        };
      },
      onHandlePropertyChange(e) {
        if (e.fullName === 'columns[4].sortOrder') {
          this.sortColumn = 'uv';
          if (e.value === 'asc') this.sortOrderDesc = false;
          else this.sortOrderDesc = true;
        }

        if (e.fullName === 'columns[5].sortOrder') {
          this.sortColumn = 'pv';
          if (e.value === 'asc') this.sortOrderDesc = false;
          else this.sortOrderDesc = true;
        }
      },
      onSearch() {
        this.selectMenuUsedData(this.startDate, this.endDate);
      },
      onPointClick({ target }) {
        target.select();
      },

      creatData() {
        this.endDate = new Date();
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.selectMenuUsedData(this.startDate, this.endDate);
      },
      /**
       * @description 메뉴 이용 현황 조회
       * @param startDate
       * @param endDate
       * @return {Promise<void>}
       */
      async selectMenuUsedData(startDate, endDate) {
        const payload = {
          actionname: 'MENU_HISTORY_LIST',
          data: {
            eventDt:
              this.$_commonlib.formatDate(startDate, 'YYYY/MM/DD', 'YYYYMMDD') +
              '~' +
              this.$_commonlib.formatDate(endDate, 'YYYY/MM/DD', 'YYYYMMDD'),
            usedHistory: true, // 메뉴 이용 현황 조회 여부
          },
          loading: false,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.menuUsedList = res.data.data;
          this.$refs.menuUseGrid.totalCount = this.getMenuUsedData.length;
          this.dataGrid.dataSource = this.getMenuUsedData;
        }
      },
    },
    created() {
      this.creatData();
    },
    mounted() {},
  };
</script>

<style>
  .menu-used-tooltip-header {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
  }

  .menu-used-tooltip-body {
    width: 170px;
  }

  .menu-used-tooltip-body .series-name {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
    line-height: 1.5;
    padding-right: 10px;
    width: 126px;
  }

  .menu-used-tooltip-body .value-text {
    display: inline-block;
    line-height: 1.5;
    width: 30px;
  }
</style>
